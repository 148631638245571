.App {
  text-align: center;
}

.container {
  /* background-color: #d8dfee;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
  /* color: white; */
  /*background-color: white;
  /* height: 100vh; */
  position: relative;
  min-height: 100vh;
}
